import { useEffect } from 'react';

import { useDeactivateOrderMutation } from '@/apolloGenerated';
import { useModal } from '@/shared';
import { toaster, Typography } from '@letsdance/ui-kit';

export const useDeactivateOrder = (): [
  (uuid: string) => void,
  { loading: boolean },
] => {
  const openConfirmModal = useModal('confirm');
  const [deactivateOrder, { client, data, error, loading }] =
    useDeactivateOrderMutation();

  const deactivate = (uuid: string) => {
    openConfirmModal({
      description: 'Вы уверены, что хотите деактивировать билет?',
      onSubmit() {
        deactivateOrder({ variables: { uuid } });
      },
      title: 'Деактивация билета',
    });
  };

  const successCompleteCallback = () => {
    client.cache.evict({ fieldName: 'product' });
    client.cache.evict({ fieldName: 'products' });
    client.cache.evict({ fieldName: 'orderByUuid' });
    client.cache.evict({ fieldName: 'ordersList' });
    client.cache.evict({ fieldName: 'users' });
  };

  useEffect(() => {
    if (error) {
      toaster.error({
        contentSlot: (
          <Typography variant="body-14">
            Попробуйте позже или обратитесь в техподдержку
          </Typography>
        ),
        title: 'Ошибка деактивации билета',
      });
    }
    if (data) {
      successCompleteCallback();
    }
  }, [data, error]);

  return [deactivate, { loading }];
};
