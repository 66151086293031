import { FC } from 'react';

import { useTelegram } from '@/shared';
import { Typography } from '@letsdance/ui-kit';

export interface TgRequirementsMessageProps {
  isTelegramClient: boolean;
  isTelegramApiUpdated: boolean;
}

export const TgRequirementsMessage: FC<TgRequirementsMessageProps> = ({
  isTelegramApiUpdated,
  isTelegramClient,
}) => {
  const { version } = useTelegram();

  return (
    <Typography variant="head-20" color="error-2">
      {!isTelegramClient && 'Please open the app from a Telegram client!'}
      {isTelegramClient && !isTelegramApiUpdated && (
        <>
          Please update Telegram to Use the app! <br />
          Telegram API version needed 6.9 or greater. <br />
          Your Telegram API version: {version}
        </>
      )}
    </Typography>
  );
};
