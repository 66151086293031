import { Maybe, SortEnum } from '@/apolloGenerated';
import { SortType } from '@letsdance/ui-kit';

export const formatAmount = (number: number, fixed?: boolean): string =>
  Intl.NumberFormat('ru-Ru', {
    currency: 'RUB',
    maximumFractionDigits: fixed ? 0 : 2,
  }).format(number);
export const formatSort = (sort: Maybe<SortType>): SortEnum | undefined => {
  if (!sort) {
    return undefined;
  }

  return sort === 'asc' ? SortEnum.Asc : SortEnum.Desc;
};
