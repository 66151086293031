import moment from 'moment-timezone';

import { TZ } from '@/shared';
import { Maybe } from '@letsdance/ui-kit';

export interface GroupEvents<T> {
  label: string;
  items: T[];
}

export const groupEvents = function <T extends { startedDate?: Maybe<Date> }>(
  events: T[],
): GroupEvents<T>[] {
  const groups: Record<string, T[]> = {};

  for (const event of events) {
    const date = moment(event.startedDate).tz(TZ);
    const label = date.isSame(moment(), 'day')
      ? 'Сегодня'
      : date.isSame(moment().add(1, 'day'), 'day')
        ? 'Завтра'
        : date.format('DD MMMM');

    if (!groups[label]) {
      groups[label] = [];
    }

    groups[label].push(event);
  }

  return Object.entries(groups).map(([label, items]) => ({
    items: items.sort(
      (a, b) => +new Date(b.startedDate!) - +new Date(a.startedDate!),
    ),
    label,
  }));
};
