import { FC } from 'react';
import { useDebounce } from 'use-debounce';

import { useEventsFiltersStore } from '@pages/QrScan/libs/store';
import { EventsList } from '@widgets/QrScan';

export const EventListView: FC = () => {
  const { search } = useEventsFiltersStore();
  const [debounceSearch] = useDebounce(search, 500);

  return <EventsList search={debounceSearch} />;
};
