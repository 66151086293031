import { create } from 'zustand';

import { EventQuery } from '@/apolloGenerated';
import { Maybe } from '@letsdance/ui-kit';

export interface EventDataType {
  event: Maybe<EventQuery['product']>;
  setEvent(event: EventQuery['product']): void;
}
export const useEventDataStore = create<EventDataType>()((set) => ({
  event: null,
  setEvent: (event: EventQuery['product']) => {
    set({ event });
  },
}));
