import { FC } from 'react';

import { createTgLink } from '@/shared';
import { BookmarkIcon, Divider, Maybe, Typography } from '@letsdance/ui-kit';

import styles from './styles.module.scss';

export interface UserItemProps {
  firstName: string;
  lastName?: Maybe<string>;
  telegram?: Maybe<string>;
  ordersCount: number;
  activatedOrdersCount: number;
  onClick(): void;
}
export const UserItem: FC<UserItemProps> = ({
  activatedOrdersCount,
  firstName,
  lastName,
  onClick,
  ordersCount,
  telegram,
}) => {
  const notActivatedOrderCount = ordersCount - activatedOrdersCount;

  return (
    <div className={styles.userItem} onClick={onClick}>
      <div>
        <Typography variant="body-16" color="on-surface-primary-1">
          {firstName} {lastName}
        </Typography>
        {telegram && (
          <Typography
            tag="a"
            // @ts-ignore
            href={createTgLink(telegram)}
            variant="body-14"
            color="on-surface-primary-2">
            t.me/{telegram}
          </Typography>
        )}
      </div>
      <div className={styles.userItem__right}>
        {!!activatedOrdersCount && (
          <div className={styles.userItem__stat}>
            <BookmarkIcon
              width={16}
              height={16}
              color="var(--on-surface-secondary-1)"
            />
            <Typography variant="body-16" color="on-surface-primary-1">
              {activatedOrdersCount}
            </Typography>
          </div>
        )}

        {!!notActivatedOrderCount && (
          <div className={styles.userItem__stat}>
            <BookmarkIcon width={16} height={16} color="var(--primary-1)" />
            <Typography variant="body-16" color="on-surface-primary-1">
              {notActivatedOrderCount}
            </Typography>
          </div>
        )}
      </div>
      <Divider className="list-item-hr" />
    </div>
  );
};
