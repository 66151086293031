import { FC, PropsWithChildren } from 'react';
import cx from 'classnames';

import { PropsWithNativeTypes } from '@letsdance/ui-kit';

import styles from './styles.module.scss';

export const Container: FC<
  PropsWithChildren<PropsWithNativeTypes<{}, HTMLDivElement>>
> = ({ children, className, ...rest }) => (
  <div className={cx(styles.container, className)} {...rest}>
    {children}
  </div>
);
