import { FC } from 'react';
import { Link } from 'react-justanother-router';

import { Head } from '@/shared';
import { RouterName } from '@app/router';
import { useEventDataStore } from '@entities/QrScan';
import { Spacer, Typography } from '@letsdance/ui-kit';

import styles from './styles.module.scss';

export const QrScanHead: FC = () => {
  const { event } = useEventDataStore();
  const routeParams = { eventUuid: event?.uuid || ' ' };

  return (
    <div className={styles.qrHead}>
      <Head backTo={RouterName.QrScan} title={event?.name} />
      <Spacer size={12} />
      <div className={styles.qrHead__info}>
        <Link
          to={RouterName.QrScanEventUserList}
          params={routeParams}
          className={styles.qrHead__infoItem}>
          <Typography variant="body-14">Использовано</Typography>
          <Spacer size={4} />
          <Typography variant="head-20">{event?.visitedCount}</Typography>
        </Link>
        <Link
          to={RouterName.QrScanEventUserList}
          params={routeParams}
          className={styles.qrHead__infoItem}>
          <Typography variant="body-14">Куплено билетов</Typography>
          <Spacer size={4} />
          <Typography variant="head-20">{event?.purchasedCount}</Typography>
        </Link>
      </div>
    </div>
  );
};
