import { GraphQLFormattedError } from 'graphql/error/GraphQLError';

import { ApolloErrorOptions, NetworkError } from '@apollo/client/errors';
import { Maybe } from '@letsdance/ui-kit';

export const ApolloErrorService = {
  debugConsoleErrors({
    graphQLErrors,
    networkError,
  }: {
    graphQLErrors?: ApolloErrorOptions['graphQLErrors'];
    networkError?: NetworkError;
  }) {
    if (graphQLErrors) {
      graphQLErrors.map(({ locations, message, path }) =>
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        ),
      );
    }

    if (networkError) {
      console.error(`[Network error]: ${networkError}`);
    }
  },
  getError(
    graphQLErrors?: ApolloErrorOptions['graphQLErrors'],
    ...strError: string[]
  ): Maybe<GraphQLFormattedError> {
    return graphQLErrors?.find((err) => strError.includes(err.message)) || null;
  },
  getFirstError(
    graphQLErrors?: ApolloErrorOptions['graphQLErrors'],
  ): Maybe<GraphQLFormattedError> {
    if (!graphQLErrors?.length) {
      return null;
    }

    return graphQLErrors[0];
  },
  hasError(
    graphQLErrors?: ApolloErrorOptions['graphQLErrors'],
    ...strError: string[]
  ): boolean {
    return !!ApolloErrorService.getError(graphQLErrors, ...strError);
  },
};
