import { gql } from '@apollo/client';

export const order = gql`
  query order($uuid: UUID!) {
    orderByUuid(uuid: $uuid) {
      createdAt
      id
      uuid
      status
      fixedHoldPercentage
      user {
        username
        first_name
        last_name
        gender
        email
        phone
      }
      pairOrder {
        user {
          uuid
          first_name
          last_name
          username
          gender
        }
        payment {
          price
        }
      }
      payment {
        price
      }
      product {
        uuid
        name
      }
      tariff {
        name
        price
      }
    }
  }
`;

export const userOrders = gql`
  query userOrders($filter: OrdersListFilterInput, $args: OrdersFilterInput) {
    ordersList(
      filters: $filter
      params: { status: [Paid, CompletedAndNotVisited, CompletedAndVisited] }
      args: $args
    ) {
      rows {
        id
        uuid
        status
        product {
          name
          startedDate
        }
        tariff {
          name
        }
      }
      count
    }
  }
`;

export const activateOrder = gql`
  mutation activateOrder($uuid: UUID!) {
    activateOrder(uuid: $uuid)
  }
`;

export const deactivateOrder = gql`
  mutation deactivateOrder($uuid: UUID!) {
    deactivateOrder(uuid: $uuid)
  }
`;
