import { FC } from 'react';

import { QrScanError } from '@entities/QrScan';
import { CloseIcon, Typography } from '@letsdance/ui-kit';

import styles from './styles.module.scss';

export interface QrErrorProps {
  error: QrScanError;
}
export const QrError: FC<QrErrorProps> = ({ error }) => (
  <div className={styles.qrError}>
    <div className={styles.qrError__wrapIcon}>
      <CloseIcon width={24} height={24} />
    </div>
    <Typography variant="body-16" color="error-3">
      {error === QrScanError.NotFound && 'Билет не найден'}
      {error === QrScanError.InvalidData && 'QR-код неверного формата'}
      {error === QrScanError.OtherEvent && 'Билет на другое событие'}
    </Typography>
  </div>
);
