import { FC, useMemo } from 'react';
import moment from 'moment-timezone';

import { GenderEnum, OrderQuery } from '@/apolloGenerated';
import { createTgLink, formatAmount, ListItem, TZ } from '@/shared';
import {
  canActionsOrder,
  getReadableOrderStatus,
  isActivatedOrder,
} from '@entities/Order/libs/utils';
import { useActivateOrder, useDeactivateOrder } from '@entities/QrScan';
import { Button } from '@letsdance/ui-kit';

import styles from './styles.module.scss';

export interface QrInfoProps {
  order: OrderQuery['orderByUuid'];
}
export const QrInfo: FC<QrInfoProps> = ({ order }) => {
  const [activate, { loading: loadingActivate }] = useActivateOrder();
  const [deactivate, { loading: loadingDeactivate }] = useDeactivateOrder();
  const user = order.user;
  const pairUser = order.pairOrder?.user;

  const price = useMemo(() => {
    const priceValue = order.tariff.price;

    return formatAmount(order.pairOrder ? priceValue / 2 : priceValue) + ' ₽';
  }, [order]);

  return (
    <div className={styles.qrInfo}>
      <div>
        <ListItem
          label="Контакты"
          value={
            <>
              {user.first_name} {user.last_name} -{' '}
              {user.gender === GenderEnum.Male ? '(М)' : '(Ж)'}{' '}
              {user.username && (
                <>
                  (
                  <a
                    style={{ color: 'inherit' }}
                    href={createTgLink(user.username)}>
                    @{user.username}
                  </a>
                  )
                </>
              )}
            </>
          }
        />
        {pairUser && (
          <ListItem
            label="Пара"
            value={
              <>
                {pairUser.first_name} {pairUser.last_name} -{' '}
                {pairUser.gender === GenderEnum.Male ? 'М' : 'Ж'}{' '}
                {pairUser.username && (
                  <>
                    (
                    <a
                      style={{ color: 'inherit' }}
                      href={createTgLink(pairUser.username)}>
                      @{pairUser.username}
                    </a>
                    )
                  </>
                )}
              </>
            }
          />
        )}

        <ListItem label="Статус" value={getReadableOrderStatus(order.status)} />
        <ListItem
          label="Дата приобретения"
          value={moment(order.createdAt).tz(TZ).format('DD.MM.YYYY HH:mm')}
        />
        <ListItem label="Тариф" value={order.tariff.name} />
        <ListItem label="Стоимость" value={price} />
      </div>
      {canActionsOrder(order.status) && (
        <>
          {isActivatedOrder(order.status) ? (
            <Button
              size="large"
              fullWidth
              loading={loadingDeactivate}
              color="danger"
              onClick={() => deactivate(order.uuid)}>
              Деактивировать билет
            </Button>
          ) : (
            <Button
              size="large"
              fullWidth
              loading={loadingActivate}
              onClick={() => activate(order.uuid)}>
              Активировать билет
            </Button>
          )}
        </>
      )}
    </div>
  );
};
