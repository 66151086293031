import { gql } from '@apollo/client';

export const actualEvents = gql`
  query actualEvents($filters: ProductListFilterInput, $organizerId: Int) {
    productsList(
      filters: $filters
      params: { organizerId: $organizerId }
      status: [Active]
    ) {
      rows {
        uuid
        startedDate
        name
        purchasedCount
        visitedCount
      }
      count
    }
  }
`;

export const event = gql`
  query event($uuid: UUID!) {
    product(uuid: $uuid) {
      uuid
      name
      description
      startedDate
      purchasedCount
      visitedCount
      receipts
    }
  }
`;
