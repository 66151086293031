import { FC, useMemo, useState } from 'react';

import { OrderSortKeys, SortEnum, useUserOrdersQuery } from '@/apolloGenerated';
import { PollingInterval, useOrganizerId } from '@/shared';
import { OrderItem } from '@entities/Order';
import { useActivateOrder, useDeactivateOrder } from '@entities/QrScan';
import { LoaderOverlay, Pagination } from '@letsdance/ui-kit';
import styles from '@widgets/QrScan/ui/EventsList/styles.module.scss';

export interface UserOrdersProps {
  uuid: string;
  eventUuid?: string;
  onClickItem(uuid: string): void;
}

const pageSize = 8;

export const UserOrders: FC<UserOrdersProps> = ({
  eventUuid,
  onClickItem,
  uuid,
}) => {
  const [activate, { loading: loadingActivate }] = useActivateOrder();
  const [deactivate, { loading: loadingDeactivate }] = useDeactivateOrder();
  const organizerId = useOrganizerId();
  const [page, setPage] = useState<number>(1);
  const {
    data: curData,
    loading,
    previousData,
  } = useUserOrdersQuery({
    pollInterval: PollingInterval.order,
    skip: !organizerId,
    variables: {
      args: {
        organizerId,
        uuidProduct: eventUuid,
        uuidUser: uuid,
      },
      filter: {
        order: { sort: SortEnum.Asc, sortBy: OrderSortKeys.CreatedAt },
        pagination: { page, pageSize },
      },
    },
  });
  const data = curData || previousData;
  const pageCount = Math.ceil(Number(data?.ordersList.count) / pageSize);

  const items = useMemo(() => data?.ordersList.rows || [], [data]);

  return (
    <div className={styles.eventList}>
      <LoaderOverlay show={loading || loadingDeactivate || loadingActivate} />
      <div>
        {items.map((el) => (
          <OrderItem
            key={el.uuid}
            event={el.product.name}
            eventDate={el.product.startedDate}
            tariff={el.tariff.name}
            status={el.status}
            onActivate={() => activate(el.uuid)}
            onDeactivate={() => deactivate(el.uuid)}
            onClick={() => onClickItem(el.uuid)}
          />
        ))}
      </div>
      {pageCount > 1 && (
        <Pagination page={page} pageCount={pageCount} onChange={setPage} />
      )}
    </div>
  );
};
