import { useEffect } from 'react';

import { useTelegram } from '@/shared';

export const useTelegramMeta = () => {
  const { setBackgroundColor, setHeaderColor } = useTelegram();

  useEffect(() => {
    setHeaderColor('#3C4D78');
    setBackgroundColor('#ffffff');
  }, []);
};
