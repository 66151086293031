import { FC, useEffect } from 'react';

import { useEventQuery, useOrderQuery, useUserQuery } from '@/apolloGenerated';
import { useEventDataStore } from '@entities/QrScan';
import { LoaderOverlay } from '@letsdance/ui-kit';
import { useUserStore } from '@pages/QrScan/libs/store';
import { EventUserViewProps } from '@pages/QrScan/ui/EventUserView/EventUserView';
import { QrScanViewProps } from '@pages/QrScan/ui/QrScanView/QrScanView';
import { QrInfo } from '@widgets/QrScan';

type EventUserOrderViewParams = QrScanViewProps['params'] &
  EventUserViewProps['params'] & { orderUuid: string };
export interface EventUserOrderViewProps {
  params: EventUserOrderViewParams;
}
export const EventUserOrderView: FC<EventUserOrderViewProps> = ({
  params: { eventUuid, orderUuid, userUuid },
}) => {
  const { setUser } = useUserStore();
  const { setEvent } = useEventDataStore();
  const { data: dataUser, loading: loadingUser } = useUserQuery({
    variables: { uuid: userUuid },
  });
  const { data: dataProduct, loading: loadingEvent } = useEventQuery({
    variables: { uuid: eventUuid },
  });
  const { data: dataOrder, loading: loadingOrder } = useOrderQuery({
    variables: { uuid: orderUuid },
  });

  useEffect(() => {
    if (dataProduct) {
      setEvent(dataProduct.product);
    }
    if (dataUser) {
      setUser(dataUser.user);
    }
  }, [dataUser, dataProduct]);

  return (
    <>
      <LoaderOverlay show={loadingUser || loadingEvent || loadingOrder} />
      {dataOrder?.orderByUuid && <QrInfo order={dataOrder?.orderByUuid} />}
    </>
  );
};
