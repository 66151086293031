import { FC, useState } from 'react';

import { SortEnum, UserSortKeys, useUsersQuery } from '@/apolloGenerated';
import { PollingInterval, useOrganizerId } from '@/shared';
import { UserItem } from '@entities/Users';
import { LoaderOverlay, Pagination } from '@letsdance/ui-kit';

import styles from './styles.module.scss';

const pageSize = 8;

export interface UsersListProps {
  eventUuid?: string;
  search?: string;
  onClickItem(uuid: string): void;
}

export const UsersList: FC<UsersListProps> = ({
  eventUuid,
  onClickItem,
  search,
}) => {
  const organizerId = useOrganizerId();
  const [page, setPage] = useState<number>(1);
  const {
    data: curData,
    loading,
    previousData,
  } = useUsersQuery({
    pollInterval: PollingInterval.users,
    skip: !organizerId,
    variables: {
      filters: {
        order: { sort: SortEnum.Asc, sortBy: UserSortKeys.CreatedAt },
        pagination: { page, pageSize },
        search,
      },
      uuidProduct: eventUuid,
    },
  });

  const data = curData || previousData;
  const pageCount = Math.ceil(Number(data?.usersByProduct.count) / pageSize);

  return (
    <div className={styles.eventList}>
      <LoaderOverlay show={loading} />
      <div>
        {data?.usersByProduct.rows.map((el) => (
          <UserItem
            key={el.uuid}
            telegram={el.username}
            firstName={el.first_name}
            lastName={el.last_name}
            ordersCount={el.ordersCount}
            activatedOrdersCount={el.activatedOrdersCount}
            onClick={() => onClickItem(el.uuid)}
          />
        ))}
      </div>
      {pageCount > 1 && (
        <Pagination page={page} pageCount={pageCount} onChange={setPage} />
      )}
    </div>
  );
};
