import { useEffect } from 'react';

import { useActivateOrderMutation } from '@/apolloGenerated';
import { toaster, Typography } from '@letsdance/ui-kit';

export const useActivateOrder = (): [
  (uuid: string) => void,
  { loading: boolean },
] => {
  const [activateOrder, { client, data, error, loading }] =
    useActivateOrderMutation();

  const activate = (uuid: string) => {
    activateOrder({ variables: { uuid } });
  };

  const successCompleteCallback = () => {
    client.cache.evict({ fieldName: 'product' });
    client.cache.evict({ fieldName: 'products' });
    client.cache.evict({ fieldName: 'orderByUuid' });
    client.cache.evict({ fieldName: 'ordersList' });
  };

  useEffect(() => {
    if (error) {
      toaster.error({
        contentSlot: (
          <Typography variant="body-14">
            Попробуйте позже или обратитесь в техподдержку
          </Typography>
        ),
        title: 'Ошибка активации билета',
      });
    }
    if (data) {
      successCompleteCallback();
    }
  }, [data, error]);

  return [activate, { loading }];
};
