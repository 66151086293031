import { create } from 'zustand';

import { OrderQuery } from '@/apolloGenerated';
import { Maybe } from '@letsdance/ui-kit';

export enum QrScanError {
  InvalidData,
  NotFound,
  OtherEvent,
}
export interface QrDataType {
  order: Maybe<OrderQuery['orderByUuid']>;
  error: Maybe<QrScanError>;
  loading: boolean;
  setError(error: Maybe<QrScanError>): void;
  setLoading(loading: boolean): void;
  setOrder(order: Maybe<OrderQuery['orderByUuid']>): void;
}
export const useQrDataStore = create<QrDataType>()((set) => ({
  error: null,
  loading: false,
  order: null,
  setError: (error: Maybe<QrScanError>) => {
    set((data) => ({ ...data, error }));
  },
  setLoading: (loading: boolean) => {
    set((data) => ({ ...data, loading }));
  },
  setOrder: (order: Maybe<OrderQuery['orderByUuid']>) => {
    set((data) => ({ ...data, order }));
  },
}));
