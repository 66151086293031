import { createElement, FC } from 'react';
import { Link } from 'react-justanother-router';
import cx from 'classnames';

import { Container, QrIcon } from '@/shared';
import { RouterName } from '@app/router';
import { IconProps, Typography, useRouterPath } from '@letsdance/ui-kit';

import styles from './styles.module.scss';

export interface NavItemProps {
  icon: FC<IconProps>;
  label: string;
  to: RouterName;
  active: boolean;
}
const NavItem: FC<NavItemProps> = ({ active, icon, label, to }) => (
  <Link
    className={cx(styles.nav__item, active && styles.nav__item_active)}
    to={to}>
    {createElement(icon, { height: 24, width: 24 })}
    <Typography variant="body-12">{label}</Typography>
  </Link>
);

export const Navigation: FC = () => {
  const { checkActivePath } = useRouterPath();

  return (
    <nav className={styles.nav}>
      <Container className={styles.nav__content}>
        <NavItem
          to={RouterName.QrScan}
          label="Сканер"
          icon={QrIcon}
          active={checkActivePath(RouterName.QrScan)}
        />
      </Container>
    </nav>
  );
};
