import { FC, useEffect } from 'react';
import { useNavigate } from 'react-justanother-router';

import { useEventQuery, useUserQuery } from '@/apolloGenerated';
import { RouterName } from '@app/router';
import { useEventDataStore } from '@entities/QrScan';
import { LoaderOverlay } from '@letsdance/ui-kit';
import { useUserStore } from '@pages/QrScan/libs/store';
import { QrScanViewProps } from '@pages/QrScan/ui/QrScanView/QrScanView';
import { UserOrders } from '@widgets/Orders';

type EventUserViewParams = QrScanViewProps['params'] & { userUuid: string };
export interface EventUserViewProps {
  params: EventUserViewParams;
}
export const EventUserView: FC<EventUserViewProps> = ({
  params: { eventUuid, userUuid },
}) => {
  const { navigate } = useNavigate();
  const { setUser } = useUserStore();
  const { event, setEvent } = useEventDataStore();
  const { data: dataUser, loading: loadingUser } = useUserQuery({
    variables: { uuid: userUuid },
  });
  const { data: dataProduct, loading: loadingEvent } = useEventQuery({
    variables: { uuid: eventUuid },
  });

  useEffect(() => {
    if (dataProduct) {
      setEvent(dataProduct.product);
    }
    if (dataUser) {
      setUser(dataUser.user);
    }
  }, [dataUser, dataProduct]);

  return (
    <div>
      <LoaderOverlay show={loadingUser || loadingEvent} />
      <UserOrders
        uuid={userUuid}
        eventUuid={event?.uuid}
        onClickItem={(orderUuid) =>
          navigate(RouterName.QrScanEventUserOrder, {
            eventUuid,
            orderUuid,
            userUuid,
          })
        }
      />
    </div>
  );
};
