import { FC, useEffect } from 'react';

import { useEventQuery } from '@/apolloGenerated';
import {
  QrError,
  QrHelp,
  useEventDataStore,
  useQrDataStore,
  useQrScanner,
} from '@entities/QrScan';
import { LoaderOverlay } from '@letsdance/ui-kit';
import { QrInfo } from '@widgets/QrScan';

export interface QrScanViewProps {
  params: {
    eventUuid: string;
  };
}
export const QrScanView: FC<QrScanViewProps> = ({
  params: { eventUuid: uuid },
}) => {
  const { setEvent } = useEventDataStore();
  const { error, loading: loadingQr, order } = useQrDataStore();
  const { data, loading: loadingEvent } = useEventQuery({
    pollInterval: 5000,
    variables: { uuid },
  });

  useQrScanner();

  useEffect(() => {
    if (data) {
      setEvent(data.product);
    }
  }, [data]);

  return (
    <>
      <LoaderOverlay show={loadingQr || loadingEvent} />
      {error ? (
        <QrError error={error} />
      ) : order ? (
        <QrInfo order={order} />
      ) : (
        <QrHelp />
      )}
    </>
  );
};
