import { FC, useEffect } from 'react';

import { SearchIcon, TextField } from '@letsdance/ui-kit';
import { useEventsFiltersStore } from '@pages/QrScan/libs/store';

export const EventListHead: FC = () => {
  const { setSearch } = useEventsFiltersStore();

  useEffect(
    () => () => {
      setSearch('');
    },
    [],
  );

  return (
    <div>
      <TextField
        size="small"
        placeholder="Найти по названию"
        fullWidth
        variant="secondary"
        onChange={(e) => setSearch(e.target.value)}
        prependSlot={<SearchIcon />}
      />
    </div>
  );
};
