import { Redirect, Route } from 'react-justanother-router';

import { LoginView } from '@pages/Auth';
import {
  EventListHead,
  EventListView,
  EventUserHead,
  EventUserListHead,
  EventUserListView,
  EventUserOrderHead,
  EventUserOrderView,
  EventUserView,
  QrScanHead,
  QrScanView,
} from '@pages/QrScan';
import { BaseLayout, UserLayout } from '@widgets/Layout';

import { RouterName } from './types';

export const routes: Route[] = [
  {
    component: () => <Redirect to={RouterName.QrScan} />,
    name: RouterName.Home,
    path: '/',
    props: {
      layout: UserLayout,
    },
  },
  {
    children: [
      {
        children: [
          {
            children: [
              {
                children: [
                  {
                    component: EventUserOrderView,
                    name: RouterName.QrScanEventUserOrder,
                    path: '/:orderUuid',
                    props: {
                      hideNav: true,
                      layout: UserLayout,
                      slotTop: EventUserOrderHead,
                      title: 'Билет',
                    },
                  },
                ],
                component: EventUserView,
                name: RouterName.QrScanEventUser,
                path: '/:userUuid',
                props: {
                  hideNav: true,
                  layout: UserLayout,
                  slotTop: EventUserHead,
                  title: 'Билеты',
                },
              },
            ],
            component: EventUserListView,
            name: RouterName.QrScanEventUserList,
            path: '/users',
            props: {
              hideNav: true,
              layout: UserLayout,
              slotTop: EventUserListHead,
              title: 'Посетители',
            },
          },
        ],
        component: QrScanView,
        name: RouterName.QrScanEvent,
        path: '/event/:eventUuid',
        props: {
          hideNav: true,
          layout: UserLayout,
          slotTop: QrScanHead,
          title: 'Сканер',
        },
      },
    ],
    component: EventListView,
    name: RouterName.QrScan,
    path: '/event',
    props: {
      layout: UserLayout,
      slotTop: EventListHead,
      title: 'Выбрать событие',
    },
  },
  {
    component: LoginView,
    name: RouterName.Login,
    path: '/login',
    props: {
      layout: BaseLayout,
    },
  },
];
