import { FC, PropsWithChildren } from 'react';

import { isDev, useTelegram, useTelegramAuth, useTelegramMeta } from '@/shared';
import {
  TgAuthError,
  TgPermissionError,
  TgRequirementsMessage,
} from '@entities/Telegram';
import { LoaderOverlay } from '@letsdance/ui-kit';

export const BaseLayout: FC<PropsWithChildren> = ({ children }) => {
  const { isVersionAtLeast, platform } = useTelegram();
  const { isError, isPermissionDenied, loading } = useTelegramAuth();
  const isTelegramApiUpdated = isVersionAtLeast('6.9');
  const isTelegramClient = platform !== 'unknown';

  useTelegramMeta();

  if ((!isTelegramClient || !isTelegramApiUpdated) && !isDev) {
    return (
      <TgRequirementsMessage
        isTelegramApiUpdated={isTelegramApiUpdated}
        isTelegramClient={isTelegramClient}
      />
    );
  }
  if (isPermissionDenied) {
    return <TgPermissionError />;
  }
  if (isError) {
    return <TgAuthError />;
  }

  return (
    <>
      <LoaderOverlay show={loading} /> {children}
    </>
  );
};
