import { FC } from 'react';

import { SearchIcon, Typography } from '@letsdance/ui-kit';

import styles from './styles.module.scss';

export const QrHelp: FC = () => (
  <div className={styles.qrHelp}>
    <div className={styles.qrHelp__wrapIcon}>
      <SearchIcon width={24} height={24} />
    </div>
    <Typography variant="body-16" color="on-surface-primary-1">
      Для проверки билета отсканируйте QR-код
    </Typography>
  </div>
);
