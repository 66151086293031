import { FC } from 'react';
import { Link } from 'react-justanother-router';

import { RouterName } from '@app/router';
import { ArrowLeftIcon, Typography } from '@letsdance/ui-kit';

import styles from './styles.module.scss';

export interface HeadProps {
  title?: string;
  backTo?: RouterName;
  backParams?: Record<string, string>;
}
export const Head: FC<HeadProps> = ({ backParams, backTo, title }) => (
  <div className={styles.head}>
    {backTo && (
      <Link to={backTo} params={backParams} className={styles.head__back}>
        <ArrowLeftIcon width={24} height={24} />
      </Link>
    )}

    <Typography className={styles.head__title} variant="head-20">
      {title}
    </Typography>
  </div>
);
