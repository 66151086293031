import { useEffect } from 'react';
import validator from 'validator';

import { useOrderLazyQuery } from '@/apolloGenerated';
import { useTelegram } from '@/shared';
import {
  QrScanError,
  useEventDataStore,
  useQrDataStore,
} from '@entities/QrScan';

export const useQrScanner = () => {
  const [getOrder, { data, error }] = useOrderLazyQuery({
    fetchPolicy: 'network-only',
  });
  const { event } = useEventDataStore();
  const { setError, setLoading, setOrder } = useQrDataStore();
  const {
    HapticFeedback,
    MainButton,
    closeScanQrPopup,
    onEvent,
    showScanQrPopup,
  } = useTelegram();

  const hapticImpact = () => {
    HapticFeedback.impactOccurred('rigid');
    HapticFeedback.impactOccurred('heavy');
  };
  const showQRScanner = () => {
    showScanQrPopup({ text: 'Отсканируйте билет' });
  };
  const processQRCode: QrTextReceivedCallback = async ({ data }) => {
    hapticImpact();
    closeScanQrPopup();
    setError(null);
    if (!validator.isUUID(data)) {
      return setError(QrScanError.InvalidData);
    }
    setLoading(true);
    await getOrder({ variables: { uuid: data } });
    setLoading(false);
  };

  useEffect(() => {
    if (data) {
      if (event?.uuid !== data.orderByUuid.product.uuid) {
        return setError(QrScanError.OtherEvent);
      }
      setOrder(data.orderByUuid);
    }
    if (error) {
      setError(QrScanError.NotFound);
    }
  }, [data, error, event]);

  useEffect(() => {
    MainButton.setText('Сканировать QR-код');
    MainButton.show();

    onEvent('mainButtonClicked', showQRScanner);
    onEvent('qrTextReceived', processQRCode);

    return () => {
      MainButton.hide();
      setOrder(null);
      setError(null);
    };
  }, []);

  return {
    showQRScanner,
  };
};
