import { create } from 'zustand';

export interface UsersFiltersStoreType {
  search: string;
  setSearch(search: string): void;
}
export const useUsersFiltersStore = create<UsersFiltersStoreType>()((set) => ({
  search: '',
  setSearch: (search: string) => {
    set((data) => ({ ...data, search }));
  },
}));
