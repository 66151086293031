import { FC } from 'react';
import { Link } from 'react-justanother-router';
import moment from 'moment-timezone';

import { TZ } from '@/shared';
import { RouterName } from '@app/router';
import { Divider, Maybe, Typography } from '@letsdance/ui-kit';

import styles from './styles.module.scss';

export interface EventItemProps {
  uuid: string;
  name: string;
  date?: Maybe<Date>;
  total: number;
  visited: number;
}
export const EventItem: FC<EventItemProps> = ({
  date,
  name,
  total,
  uuid,
  visited,
}) => (
  <Link
    to={RouterName.QrScanEvent}
    params={{ eventUuid: uuid }}
    className={styles.eventItem}>
    <div>
      <Typography variant="body-16" color="on-surface-primary-1">
        {name}
      </Typography>
      {date && (
        <Typography variant="body-14" color="on-surface-primary-2">
          {moment(date).tz(TZ).format('DD.MM.YYYY HH:mm')}
        </Typography>
      )}
    </div>
    <div className={styles.eventItem__right}>
      <Typography variant="body-16" color="on-surface-primary-1">
        {total ? `${visited}/${total}` : '-'}
      </Typography>
    </div>
    <Divider className="list-item-hr" />
  </Link>
);
