import { FC } from 'react';

import { Typography } from '@letsdance/ui-kit';

export const TgAuthError: FC = () => (
  <Typography variant="head-20" color="error-2">
    Ошибка авторизации. <br />
    Попробуйте позже или обратитесь в техподдержку
  </Typography>
);
