import { FC } from 'react';
import cx from 'classnames';
import moment from 'moment-timezone';

import { OrderStatusEnum } from '@/apolloGenerated';
import { TZ } from '@/shared';
import { isActivatedOrder } from '@entities/Order/libs/utils';
import {
  Button,
  Divider,
  Maybe,
  stopEvent,
  Typography,
} from '@letsdance/ui-kit';

import styles from './styles.module.scss';

export interface OrderItemProps {
  event: string;
  eventDate?: Maybe<Date>;
  tariff: string;
  status: OrderStatusEnum;
  onActivate(): void;
  onDeactivate(): void;
  onClick(): void;
}
export const OrderItem: FC<OrderItemProps> = ({
  event,
  eventDate,
  onActivate,
  onClick,
  onDeactivate,
  status,
}) => (
  <div className={styles.orderItem} onClick={onClick}>
    <div>
      <Typography variant="body-16" color="on-surface-primary-1">
        {event}
      </Typography>
      <Typography variant="body-14" color="on-surface-primary-2">
        {moment(eventDate!).tz(TZ).format('DD.MM.YY HH.mm')}
      </Typography>
    </div>
    <div className={styles.orderItem__right}>
      {isActivatedOrder(status) ? (
        <Button
          className={cx(styles.orderItem__btn, styles.orderItem__btn_red)}
          variant="outlined"
          onClick={(e) => {
            stopEvent(e);
            onDeactivate();
          }}>
          Деактивировать
        </Button>
      ) : (
        <Button
          className={cx(styles.orderItem__btn, styles.orderItem__btn_green)}
          variant="outlined"
          onClick={(e) => {
            stopEvent(e);
            onActivate();
          }}>
          Активировать
        </Button>
      )}
    </div>
    <Divider className="list-item-hr" />
  </div>
);
