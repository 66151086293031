import { FC, useMemo, useState } from 'react';

import {
  ProductSearchKeysEnum,
  ProductSortKeys,
  SortEnum,
  useActualEventsQuery,
} from '@/apolloGenerated';
import { PollingInterval, useOrganizerId } from '@/shared';
import { EventItem } from '@entities/Event';
import { LoaderOverlay, Pagination, Typography } from '@letsdance/ui-kit';
import { groupEvents } from '@widgets/QrScan/libs/utils';

import styles from './styles.module.scss';

const pageSize = 5;

export interface EventsListProps {
  search?: string;
}

export const EventsList: FC<EventsListProps> = ({ search }) => {
  const organizerId = useOrganizerId();
  const [page, setPage] = useState<number>(1);
  const {
    data: curData,
    loading,
    previousData,
  } = useActualEventsQuery({
    pollInterval: PollingInterval.events,
    skip: !organizerId,
    variables: {
      filters: {
        order: { sort: SortEnum.Asc, sortBy: ProductSortKeys.StartedDate },
        pagination: { page, pageSize },
        search: search
          ? { search, searchBy: ProductSearchKeysEnum.Name }
          : null,
      },
      organizerId,
    },
  });
  const data = curData || previousData;
  const pageCount = Math.ceil(Number(data?.productsList.count) / pageSize);

  const items = useMemo(
    () => groupEvents(data?.productsList.rows || []),
    [data],
  );

  return (
    <div className={styles.eventList}>
      <LoaderOverlay show={loading} />
      <div>
        {items.map(({ items, label }, index) => (
          <div key={index}>
            <Typography
              variant="head-16"
              color="on-surface-primary-1"
              className={styles.eventList__groupLabel}>
              {label}
            </Typography>
            {items.map((el, i) => (
              <EventItem
                key={i}
                uuid={el.uuid}
                total={el.purchasedCount}
                name={el.name}
                date={el.startedDate}
                visited={el.visitedCount}
              />
            ))}
          </div>
        ))}
      </div>
      {pageCount > 1 && (
        <Pagination page={page} pageCount={pageCount} onChange={setPage} />
      )}
    </div>
  );
};
