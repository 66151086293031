import { FC } from 'react';

import { Divider, Typography, TypographyProps } from '@letsdance/ui-kit';

import styles from './styles.module.scss';

export interface QrInfoItemProps {
  label: string;
  value: string | number | JSX.Element;
  color?: TypographyProps['color'];
}
export const ListItem: FC<QrInfoItemProps> = ({
  color = 'on-surface-primary-1',
  label,
  value,
}) => (
  <div className={styles.listItem}>
    <Typography variant="body-14" color="on-surface-primary-2">
      {label}
    </Typography>
    <Typography variant="body-16" color={color}>
      {value}
    </Typography>
    <Divider className="list-item-hr" />
  </div>
);
