import { FC } from 'react';

import { Head } from '@/shared';
import { RouterName } from '@app/router';
import { useEventDataStore } from '@entities/QrScan';
import { useUserStore } from '@pages/QrScan/libs/store';

export const EventUserOrderHead: FC = () => {
  const { user } = useUserStore();
  const { event } = useEventDataStore();

  return (
    <div>
      <Head
        title={`${user?.last_name} ${user?.first_name}`}
        backTo={RouterName.QrScanEventUser}
        backParams={{
          eventUuid: event?.uuid || ' ',
          userUuid: user?.uuid || ' ',
        }}
      />
    </div>
  );
};
