import { gql } from '@apollo/client';

export const users = gql`
  query users($filters: UserListFilterInput, $uuidProduct: UUID!) {
    usersByProduct(filters: $filters, uuidProduct: $uuidProduct) {
      rows {
        uuid
        createdAt
        first_name
        last_name
        username
        email
        phone
        gender
        ordersCount
        activatedOrdersCount
      }
      count
    }
  }
`;
export const user = gql`
  query user($uuid: UUID!) {
    user(uuid: $uuid) {
      uuid
      createdAt
      first_name
      last_name
      username
      email
      phone
      gender
      ordersCount
    }
  }
`;
