import { create } from 'zustand';

export interface EventsFiltersStoreType {
  search: string;
  setSearch(search: string): void;
}
export const useEventsFiltersStore = create<EventsFiltersStoreType>()(
  (set) => ({
    search: '',
    setSearch: (search: string) => {
      set((data) => ({ ...data, search }));
    },
  }),
);
