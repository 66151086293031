import { Router } from 'react-justanother-router';

import { ConfirmModal } from '@/shared';
import { ApolloProvider } from '@apollo/client';
import { apolloClient } from '@app/apollo';
import { GoblinProvider, ModalProviderProps, Toaster } from '@letsdance/ui-kit';

import './configs';

import { history, renderer, router } from './router';

import './styles/index.scss';

const componentDictionary: ModalProviderProps['components'] = {
  confirm: ConfirmModal,
};

export const Root = () => (
  <ApolloProvider client={apolloClient}>
    <GoblinProvider modalComponents={componentDictionary}>
      <Router history={history} router={router} renderer={renderer}>
        <Toaster />
      </Router>
    </GoblinProvider>
  </ApolloProvider>
);
