import { create } from 'zustand';

import { UserQuery } from '@/apolloGenerated';
import { Maybe } from '@letsdance/ui-kit';

export interface UserStoreType {
  user: Maybe<UserQuery['user']>;
  setUser(user: UserQuery['user']): void;
}
export const useUserStore = create<UserStoreType>()((set) => ({
  setUser: (user: UserQuery['user']) => {
    set((data) => ({ ...data, user }));
  },
  user: null,
}));
