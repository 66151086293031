import { FC } from 'react';

import {
  Button,
  Dialog,
  DialogFooter,
  Typography,
  WithCloseModal,
} from '@letsdance/ui-kit';

export interface ConfirmModalProps {
  title: string;
  description?: string;
  onSubmit?(): void;
}
declare global {
  interface ModalProps {
    confirm: ConfirmModalProps;
  }
}
export const ConfirmModal: FC<WithCloseModal<ConfirmModalProps>> = ({
  description,
  onClose,
  onSubmit,
  title,
}) => {
  const handleSubmit = () => {
    onSubmit && onSubmit();
    onClose();
  };

  return (
    <Dialog
      title={title}
      footerSlot={
        <DialogFooter
          fullWidth
          startSlot={
            <Button size="large" color="secondary" fullWidth onClick={onClose}>
              Отменить
            </Button>
          }
          endSlot={
            <Button
              onClick={handleSubmit}
              size="large"
              color="danger"
              fullWidth>
              Подтвердить
            </Button>
          }
        />
      }>
      {description && (
        <Typography variant="body-16" color="on-surface-primary-1">
          {description}
        </Typography>
      )}
    </Dialog>
  );
};
