import { FC, useEffect } from 'react';
import { useNavigate } from 'react-justanother-router';
import { useDebounce } from 'use-debounce';

import { useEventQuery } from '@/apolloGenerated';
import { RouterName } from '@app/router';
import { useEventDataStore } from '@entities/QrScan';
import { LoaderOverlay } from '@letsdance/ui-kit';
import { useUsersFiltersStore } from '@pages/QrScan/libs/store';
import { QrScanViewProps } from '@pages/QrScan/ui/QrScanView/QrScanView';
import { UsersList } from '@widgets/Users';

type EventUsersViewParams = QrScanViewProps['params'];
export interface EventUsersViewProps {
  params: EventUsersViewParams;
}
export const EventUserListView: FC<EventUsersViewProps> = ({
  params: { eventUuid },
}) => {
  const { navigate } = useNavigate();
  const { setEvent } = useEventDataStore();
  const { search } = useUsersFiltersStore();
  const [debounceSearch] = useDebounce(search, 500);
  const { data, loading: loadingEvent } = useEventQuery({
    variables: { uuid: eventUuid },
  });

  useEffect(() => {
    if (data) {
      setEvent(data.product);
    }
  }, [data]);

  return (
    <>
      <LoaderOverlay show={loadingEvent} />
      <UsersList
        eventUuid={eventUuid}
        search={debounceSearch}
        onClickItem={(userUuid) =>
          navigate(RouterName.QrScanEventUser, { eventUuid, userUuid })
        }
      />
    </>
  );
};
