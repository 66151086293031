import { FC, PropsWithChildren } from 'react';
import { Link } from 'react-justanother-router';

import { MeQuery } from '@/apolloGenerated';
import { Container, useTelegram } from '@/shared';
import { RouterName } from '@app/router';
import { Avatar, LogoutIcon, Typography } from '@letsdance/ui-kit';

import styles from './styles.module.scss';

export interface HeaderProps {
  title?: string;
  user?: MeQuery['me'];
  hideHead?: boolean;
}
export const Header: FC<PropsWithChildren<HeaderProps>> = ({
  children,
  hideHead,
  title,
  user,
}) => {
  const { close } = useTelegram();

  return (
    <div className={styles.header}>
      <Container>
        {!hideHead && (
          <header className={styles.header__head}>
            <Link to={RouterName.QrScan}>
              <Avatar size={32} name={user?.username || 'U'} />
            </Link>
            <Typography color="on-primary-1" variant="head-24">
              {title}
            </Typography>
            <LogoutIcon height={24} width={24} onClick={() => close()} />
          </header>
        )}
        {children && <div className={styles.header__content}>{children}</div>}
      </Container>
    </div>
  );
};
