import { FC, useEffect } from 'react';

import { Head } from '@/shared';
import { RouterName } from '@app/router';
import { useEventDataStore } from '@entities/QrScan';
import { SearchIcon, Spacer, TextField } from '@letsdance/ui-kit';
import { useUsersFiltersStore } from '@pages/QrScan/libs/store';

export const EventUserListHead: FC = () => {
  const { event } = useEventDataStore();
  const { setSearch } = useUsersFiltersStore();

  useEffect(
    () => () => {
      setSearch('');
    },
    [],
  );

  return (
    <div>
      <Head
        title={event?.name}
        backTo={RouterName.QrScanEvent}
        backParams={{ eventUuid: event?.uuid || ' ' }}
      />
      <Spacer size={8} />
      <TextField
        size="small"
        placeholder="Telegram, фамилия, почта, телефон"
        fullWidth
        variant="secondary"
        onChange={(e) => setSearch(e.target.value)}
        prependSlot={<SearchIcon />}
      />
    </div>
  );
};
