import Cookie from 'js-cookie';

import {
  JwtType,
  RefreshMutation,
  RefreshMutationVariables,
} from '@/apolloGenerated';
import { apolloClient } from '@app/apollo';

import * as query from '../../query';

export const USER_LOCALSTORAGE_KEY = 'auth.w2dance-organizer';

let localUserHook: JwtType | null = null;

export const loadUser = (): JwtType | null => {
  if (localUserHook) {
    return localUserHook;
  }

  const base64token = Cookie.get(USER_LOCALSTORAGE_KEY);

  if (!base64token) {
    return null;
  }

  try {
    return JSON.parse(window.atob(base64token));
  } catch (error) {
    console.error(error);

    return null;
  }
};

export const logoutUser = (): void => {
  localUserHook = null;
  Cookie.remove(USER_LOCALSTORAGE_KEY);
};

export const persistUser = (data: JwtType): void => {
  localUserHook = data;
  Cookie.set(USER_LOCALSTORAGE_KEY, window.btoa(JSON.stringify(data)));
};

export const setAuthorizationHeaders = (
  headers?: object,
  token?: string,
  refreshToken?: string,
) => {
  if (headers === undefined) {
    headers = {};
  }

  if (
    (!token ||
      Object.prototype.hasOwnProperty.call(headers, 'Authorization')) &&
    !refreshToken
  ) {
    return headers;
  }

  return {
    ...headers,
    Authorization: `Bearer ${token}`,
  };
};

export const getNewTokenDirect = async (): Promise<
  JwtType | null | undefined
> => {
  const user: JwtType | null = loadUser();

  if (!user) {
    return new Promise((resolve, reject) => reject('user not found'));
  }

  try {
    const resp = await apolloClient.mutate<
      RefreshMutation,
      RefreshMutationVariables
    >({
      context: {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
      },
      mutation: query.refresh,
      variables: {
        refresh_token: user.refresh_token!,
      },
    });

    return resp.data?.refresh;
  } catch (error) {
    console.error(error);

    return null;
  }
};
